<template>
    <div>
        <div class="hb-primary hb-design-system-border-radius px-4 white--text mb-6">
            <hb-header :divider="false">
                <hb-page-header title="Forms">
                    <template v-slot:description>
                        <div>import { HbAutocomplete, HbCombobox, HbFileInput, HbSelect, HbTextField, HbTextarea } from 'hummingbird-aviary';</div>
                    </template>
                </hb-page-header>
            </hb-header>
        </div>

        <hb-card title="Basic Form Row Structure + Label Variations + Code" class="mt-4 mb-6">

            <hb-form label="Label">
                Put Input/Code Here such as HbTextField, HbSelect, HbAutocomplete, HbTextArea, etc... (setting a string for 'label' prop is required when using HbForm)
            </hb-form>

            <hb-form
                label="Label with Required and Description"
                required
                description="Use 'description' prop to set description text."
            >
                Use required prop to add asterik* to label.
            </hb-form>

            <hb-form label="Label With Tooltip">
                <template v-slot:tooltipHeader>
                    v-slot:tooltipHeader
                </template>
                <template v-slot:tooltipBody>
                    v-slot:tooltipBody
                </template>
                Set either the "tooltipHeader" slot or "tooltipBody" slot (or both) in order for the tooltip icon to show up next to the label
            </hb-form>

        <hb-card class="pa-6" color="#E0F5F5" no-title>
<pre>
&lt;hb-form label="Label">
    Put Input/Code Here such as HbTextField, HbSelect, HbAutocomplete, HbTextArea, etc... (setting 'label' prop is required when using HbForm)
&lt;/hb-form>

&lt;hb-form 
    label="Label with Required and Description"
    required
    description="Use 'description' prop to set description text."
>
    Use required prop to add asterik* to label.
&lt;/hb-form>

&lt;hb-form label="Label With Tooltip">

    &lt;template v-slot:tooltipHeader>
        v-slot:tooltipHeader
    &lt;/template>

    &lt;template v-slot:tooltipBody>
        v-slot:tooltipBody
    &lt;/template>

    Set either the "tooltipHeader" slot or "tooltipBody" slot (or both) in order for the tooltip icon to show up next to the label

&lt;/hb-form>
</pre>
            </hb-card>
        </hb-card>

        <hb-card class="mt-4 mb-6">
            <template v-slot:title>
                Default Open Form Example + Test the Validation + Code
                <v-spacer></v-spacer>
                <hb-btn small @click="validateOpenForm">Test Validation</hb-btn>
            </template>

            <hb-form label="Text Field" required>
                <HbTextField
                    v-model="textField"
                    v-validate="'required|max:45|email'"
                    data-vv-scope="default"
                    data-vv-name="email"
                    data-vv-as="Email"
                    :error="errors.has('default.email')"
                    placeholder="Enter Email"
                />
            </hb-form>

            <hb-form label="Select" required>
                <HbSelect
                    v-model="selectField"
                    :items="['Office','Fax']"
                    v-validate="'required|max:45'"
                    data-vv-scope="default"
                    data-vv-name="phone"
                    data-vv-as="Phone"
                    :error="errors.has('default.phone')"
                    placeholder="Select Phone"
                />
            </hb-form>

            <hb-form label="Autocomplete" required>
                <HbAutocomplete
                    v-model="autocompleteField"
                    :items="['USA','Canada']"
                    v-validate="'required|max:45'"
                    data-vv-scope="default"
                    data-vv-name="country"
                    data-vv-as="Country"
                    :error="errors.has('default.country')"
                    placeholder="Select Country"
                />
            </hb-form>

            <hb-form label="Textarea" required full>
                <HbTextarea
                    v-model="textareaField"
                    v-validate="'required|max:1000'"
                    data-vv-scope="default"
                    data-vv-name="message"
                    data-vv-as="Message"
                    :error="errors.has('default.message')"
                    placeholder="Enter Message"
                    placeholder-fix
                />
            </hb-form>

            <hb-form label="Combobox" required>
                <HbCombobox
                    v-model="combobox"
                    :items="items"
                    v-validate="'required|max:45'"
                    data-vv-scope="default"
                    data-vv-name="wizards"
                    data-vv-as="Wizards"
                    :error="errors.has('default.wizards')"
                    label="Select or Manually Enter New Wizards"
                />
            </hb-form>

            <hb-form label="File Input" required>
                <HbFileInput
                    v-model="fileInputField"
                    v-validate="'required'"
                    data-vv-scope="default"
                    data-vv-name="file_input"
                    data-vv-as="File Input"
                    :error="errors.has('default.file_input')"
                />
            </hb-form>

            <hb-form label="Multiple" description="Use 'description' prop to set description text." required>
                <div>
                    <HbTextField
                        v-model="textFieldType"
                        v-validate="'required|max:45'"
                        data-vv-scope="default"
                        data-vv-name="type"
                        data-vv-as="Type"
                        :error="errors.has('default.type')"
                        placeholder="Enter Type"
                    />
                </div>
                <div class="mt-3">
                    <HbTextField
                        v-model="textFieldMethod"
                        v-validate="'required|max:45'"
                        data-vv-scope="default"
                        data-vv-name="method"
                        data-vv-as="Method"
                        :error="errors.has('default.method')"
                        placeholder="Enter Method"
                    />
                </div>
                <div class="mt-3">
                    <HbTextField
                        v-model="textFieldReason"
                        v-validate="'required|max:45'"
                        data-vv-scope="default"
                        data-vv-name="reason"
                        data-vv-as="Reason"
                        :error="errors.has('default.reason')"
                        placeholder="Enter Reason"
                    />
                </div>
            </hb-form>

            <hb-form label="Multiple on Same Line with Top Label Helper (rarely used)" multiple required>
                <v-row no gutters>
                    <v-col cols="12" sm="6" class="pt-0 pb-0" :class="{'pt-5' : ((textFieldFirstNameFocused || textFieldFirstName.length || textFieldLastNameFocused || textFieldLastName.length) && $vuetify.breakpoint.smAndUp) || ((textFieldFirstNameFocused || textFieldFirstName.length) && $vuetify.breakpoint.xs), 'pr-3' : $vuetify.breakpoint.smAndUp}">
                        <v-text-field
                            v-model="textFieldFirstName"
                            v-validate="'required|max:45'"
                            data-vv-scope="default"
                            data-vv-name="first"
                            data-vv-as="First"
                            :error="errors.has('default.first')"
                            label="First"
                            hide-details
                            @focus="textFieldFirstNameFocused = true"
                            @focusout="textFieldFirstNameFocused = false"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" class="pt-0 pb-0" :class="{'pt-5' : ((textFieldFirstNameFocused || textFieldFirstName.length || textFieldLastNameFocused || textFieldLastName.length) && $vuetify.breakpoint.smAndUp) || ((!textFieldLastNameFocused && textFieldLastName.length === 0) && $vuetify.breakpoint.xs), 'pt-9' : (textFieldLastNameFocused || textFieldLastName.length) && $vuetify.breakpoint.xs}">
                        <v-text-field
                            v-model="textFieldLastName"
                            v-validate="'required|max:45'"
                            data-vv-scope="default"
                            data-vv-name="last"
                            data-vv-as="Last"
                            :error="errors.has('default.last')"
                            label="Last"
                            hide-details
                            @focus="textFieldLastNameFocused = true"
                            @focusout="textFieldLastNameFocused = false"
                        ></v-text-field>
                    </v-col>
                </v-row>
            </hb-form>

        <hb-card class="pa-6" color="#E0F5F5" no-title>
<pre>
&lt;hb-form label="Text Field" required>
    &lt;HbTextField
        v-model="textField"
        v-validate="'required|max:45|email'"
        data-vv-scope="default"
        data-vv-name="email"
        data-vv-as="Email"
        :error="errors.has('default.email')"
        placeholder="Enter Email"
    />
&lt;/hb-form>

&lt;hb-form label="Select" required>
    &lt;HbSelect
        v-model="selectField"
        :items="['Office','Fax']"
        v-validate="'required|max:45'"
        data-vv-scope="default"
        data-vv-name="phone"
        data-vv-as="Phone"
        :error="errors.has('default.phone')"
        placeholder="Select Phone"
    />
&lt;/hb-form>

&lt;hb-form label="Autocomplete" required>
    &lt;HbAutocomplete
        v-model="autocompleteField"
        :items="['USA','Canada']"
        v-validate="'required|max:45'"
        data-vv-scope="default"
        data-vv-name="country"
        data-vv-as="Country"
        :error="errors.has('default.country')"
        placeholder="Select Country"
    />
&lt;/hb-form>

&lt;hb-form label="Textarea" required full>
    &lt;HbTextarea
        v-model="textareaField"
        v-validate="'required|max:1000'"
        data-vv-scope="default"
        data-vv-name="message"
        data-vv-as="Message"
        :error="errors.has('default.message')"
        placeholder="Enter Message"
        placeholder-fix
    />
&lt;/hb-form>

&lt;hb-form label="Combobox" required>
    &lt;HbCombobox
        v-model="combobox"
        :items="items"
        v-validate="'required|max:45'"
        data-vv-scope="default"
        data-vv-name="wizards"
        data-vv-as="Wizards"
        :error="errors.has('default.wizards')"
        label="Select or Manually Enter New Wizards"
    />
&lt;/hb-form>

&lt;hb-form label="File Input" required>
    &lt;HbFileInput
        v-model="fileInputField"
        v-validate="'required'"
        data-vv-scope="default"
        data-vv-name="file_input"
        data-vv-as="File Input"
        :error="errors.has('default.file_input')"
    />
&lt;/hb-form>

&lt;hb-form label="Multiple" description="Use 'description' prop to set description text." required>
    &lt;div>
        &lt;HbTextField
            v-model="textFieldType"
            v-validate="'required|max:45'"
            data-vv-scope="default"
            data-vv-name="type"
            data-vv-as="Type"
            :error="errors.has('default.type')"
            placeholder="Enter Type"
        />
    &lt;/div>
    &lt;div class="mt-3">
        &lt;HbTextField
            v-model="textFieldMethod"
            v-validate="'required|max:45'"
            data-vv-scope="default"
            data-vv-name="method"
            data-vv-as="Method"
            :error="errors.has('default.method')"
            placeholder="Enter Method"
        />
    &lt;/div>
    &lt;div class="mt-3">
        &lt;HbTextField
            v-model="textFieldReason"
            v-validate="'required|max:45'"
            data-vv-scope="default"
            data-vv-name="reason"
            data-vv-as="Reason"
            :error="errors.has('default.reason')"
            placeholder="Enter Reason"
        />
    &lt;/div>
&lt;/hb-form>

&lt;hb-form label="Multiple on Same Line with Top Label Helper" multiple required>
    &lt;v-row no gutters>
        &lt;v-col cols="12" sm="6" class="pt-0 pb-0" :class="{'pt-5' : ((textFieldFirstNameFocused || textFieldFirstName.length || textFieldLastNameFocused || textFieldLastName.length) &amp;&amp; $vuetify.breakpoint.smAndUp) || ((textFieldFirstNameFocused || textFieldFirstName.length) &amp;&amp; $vuetify.breakpoint.xs), 'pr-3' : $vuetify.breakpoint.smAndUp}">
            &lt;v-text-field
                v-model="textFieldFirstName"
                v-validate="'required|max:45'"
                data-vv-scope="default"
                data-vv-name="first"
                data-vv-as="First"
                :error="errors.has('default.first')"
                label="First"
                hide-details
                @focus="textFieldFirstNameFocused = true"
                @focusout="textFieldFirstNameFocused = false"
            >&lt;/v-text-field>
        &lt;/v-col>
        &lt;v-col cols="12" sm="6" class="pt-0 pb-0" :class="{'pt-5' : ((textFieldFirstNameFocused || textFieldFirstName.length || textFieldLastNameFocused || textFieldLastName.length) &amp;&amp; $vuetify.breakpoint.smAndUp) || ((!textFieldLastNameFocused &amp;&amp; textFieldLastName.length === 0) &amp;&amp; $vuetify.breakpoint.xs), 'pt-9' : (textFieldLastNameFocused || textFieldLastName.length) &amp;&amp; $vuetify.breakpoint.xs}">
            &lt;v-text-field
                v-model="textFieldLastName"
                v-validate="'required|max:45'"
                data-vv-scope="default"
                data-vv-name="last"
                data-vv-as="Last"
                :error="errors.has('default.last')"
                label="Last"
                hide-details
                @focus="textFieldLastNameFocused = true"
                @focusout="textFieldLastNameFocused = false"
            >&lt;/v-text-field>
        &lt;/v-col>
    &lt;/v-row>
&lt;/hb-form>

&lt;hb-global-notification
    v-model="snackbar"
    :type="type"
    :list="type === 'error' &amp;&amp; list ? errors.items : []"
    :description="description"
>
&lt;/hb-global-notification>

validateOpenForm(){
    this.$validator.validateAll('default').then(valid => {
        if (!valid) {
            this.list = true;
            this.description = "There are errors in your form, correct them before continuing."
            this.type = 'error';
            this.snackbar = true;
        } else {
            this.description = "All fields passed validation. This message is not intended for a normal user to see. It is just here to notify developers that the validation was successful."
            this.type = 'success';
            this.snackbar = true;
        }
    });
}
</pre>
            </hb-card>
        </hb-card>

        <hb-card title="Alternate Editable Form Example + Slot Usage + Code" class="mt-4 mb-6">
            
            <hb-form label="Slot Usage" description="Use 'description' prop to set description text." @cancel="handleEmittedCancel" @save="handleEmittedSave" :active.sync="active" editable @editStart="editHasStarted">
                <template v-slot:before>
                    v-slot:before
                </template>
                <template v-slot:display>
                    {{ slotExample }}
                </template>
                <template v-slot:edit>
                    <HbTextField v-model="slotExample" />
                </template>
                <template v-slot:after>
                    v-slot:after
                </template>
            </hb-form>

            <hb-form label="Empty" @cancel="handleEmittedCancel" @save="handleEmittedSave" :active.sync="active" editable :empty="!textFieldEditable" @editStart="editHasStarted">
                <template v-slot:display>
                    {{ textFieldEditable }}
                </template>
                <template v-slot:edit>
                    <HbTextField
                        v-model="textFieldEditableInput"
                        v-validate="'required|max:45'"
                        id="name"
                        name="name"
                        data-vv-as="Name"
                        :error="errors.has('name')"
                        placeholder="Enter Name"
                        autofocus
                    />
                </template>
            </hb-form>

            <hb-form label="Already Filled" @cancel="handleEmittedCancel" @save="handleEmittedSave" :active.sync="active" editable :empty="!textFieldEditablePrefilled" @editStart="editHasStarted">
                <template v-slot:display>
                    {{ textFieldEditablePrefilled }}
                </template>
                <template v-slot:edit>
                    <HbTextField
                        v-model="textFieldEditablePrefilledInput"
                        v-validate="'required|max:45'"
                        id="address"
                        name="address"
                        data-vv-as="Address"
                        :error="errors.has('address')"
                        placeholder="Enter Address"
                        autofocus
                    />
                </template>
            </hb-form>

        <hb-card class="pa-6" color="#E0F5F5" no-title>
<pre>
&lt;hb-form label="Slot Usage" description="Use 'description' prop to set description text." @cancel="handleEmittedCancel" @save="handleEmittedSave" :active.sync="active" editable @editStart="editHasStarted">
    &lt;template v-slot:before>
        v-slot:before
    &lt;/template>
    &lt;template v-slot:display>
        <span v-pre>{{ slotExample }}</span>
    &lt;/template>
    &lt;template v-slot:edit>
        &lt;HbTextField v-model="slotExample" />
    &lt;/template>
    &lt;template v-slot:after>
        v-slot:after
    &lt;/template>
&lt;/hb-form>

&lt;hb-form label="Empty" @cancel="handleEmittedCancel" @save="handleEmittedSave" :active.sync="active" editable :empty="!textFieldEditable" @editStart="editHasStarted">
    &lt;template v-slot:display>
        <span v-pre>{{ textFieldEditable }}</span>
    &lt;/template>
    &lt;template v-slot:edit>
        &lt;HbTextField
            v-model="textFieldEditableInput"
            v-validate="'required|max:45'"
            id="name"
            name="name"
            data-vv-as="Name"
            :error="errors.has('name')"
            placeholder="Enter Name"
            autofocus
        />
    &lt;/template>
&lt;/hb-form>

&lt;hb-form label="Already Filled" @cancel="handleEmittedCancel" @save="handleEmittedSave" :active.sync="active" editable :empty="!textFieldEditablePrefilled" @editStart="editHasStarted">
    &lt;template v-slot:display>
        <span v-pre>{{ textFieldEditablePrefilled }}</span>
    &lt;/template>
    &lt;template v-slot:edit>
        &lt;HbTextField
            v-model="textFieldEditablePrefilledInput"
            v-validate="'required|max:45'"
            id="address"
            name="address"
            data-vv-as="Address"
            :error="errors.has('address')"
            placeholder="Enter Address"
            autofocus
        />
    &lt;/template>
&lt;/hb-form>

&lt;hb-global-notification
    v-model="snackbar"
    :type="type"
    :list="type === 'error' &amp;&amp; list ? errors.items : []"
    :description="description"
>
&lt;/hb-global-notification>

handleEmittedCancel(label){
    switch(label){
        case 'Empty':
            this.textFieldEditableInput = this.textFieldEditable;
            break;
        case 'Already Filled':
            this.textFieldEditablePrefilledInput = this.textFieldEditablePrefilled;
            break;
    }
    this.list = false;
    this.description = 'The editing of the form row: "' + label +'" was canceled. This "@cancel" action is emitted from it\'s base level custom component. This message is not intended for a normal user to see. It is just here to notify developers of the emitted event.'
    this.type = 'error';
    this.snackbar = true;
},

handleEmittedSave(label){
    let param = '';
    switch(label){
        case 'Empty':
            param = 'name';
            break;
        case 'Already Filled':
            param = 'address';
            break;
    }
    this.$validator.validate(param).then(valid => {
        if (!valid) {
            this.list = true;
            this.description = "There are errors in your form, correct them before continuing."
            this.type = 'error';
            this.snackbar = true;
            return;
        } else {
            switch(label){
                case 'Empty':
                    this.textFieldEditable = this.textFieldEditableInput;
                    break;
                case 'Already Filled':
                    this.textFieldEditablePrefilled = this.textFieldEditablePrefilledInput;
                break;
            }
            this.active = '';
            this.description = 'The saving of form row: "' + label + '" was successful. This "@save" action is emitted from it\'s base level custom component. This message is not intended for a normal user to see. It is just here to notify developers of the emitted event.'
            this.type = 'success';
            this.snackbar = true;
        }
    });
},
editHasStarted(label){
    this.description = 'The editing of form row "' + label + '" has started.  This "@editStart" action is emitted from it\'s base level custom component.'
    this.type = 'success';
    this.snackbar = true;
}
</pre>
            </hb-card>
        </hb-card>

        <hb-card title="Validation Error Visual Styling Examples" class="mt-4 mb-6">

            <hb-form label="Text Field" required>
                <HbTextField
                    v-model="textFieldEmpty"
                    placeholder="Enter Email"
                    error
                />
            </hb-form>

            <hb-form label="Select" required>
                <HbSelect
                    v-model="selectFieldEmpty"
                    :items="['Office','Fax']"
                    placeholder="Select Phone"
                    error
                />
            </hb-form>

            <hb-form label="Autocomplete" required>
                <HbAutocomplete
                    v-model="autocompleteFieldEmpty"
                    :items="['USA','Canada']"
                    placeholder="Select Country"
                    error
                />
            </hb-form>

            <hb-form label="Textarea" required full>
                <HbTextarea
                    v-model="textareaFieldEmpty"
                    placeholder="Enter Message"
                    error
                    placeholder-fix
                />
            </hb-form>
        </hb-card>

        <hb-card title="Props" class="my-6 pb-1">
            <hb-data-table
                :headers="propHeaders"
                :items="propItems"
                class="pa-6"
            >
            </hb-data-table>
        </hb-card>

        <hb-card title="Slots" class="my-6 pb-1">
            <hb-data-table
                :headers="slotHeaders"
                :items="slotItems"
                class="pa-6"
                >
            </hb-data-table>
        </hb-card>

        <hb-card title="Events" class="my-6 pb-1">
            <hb-data-table
                :headers="eventHeaders"
                :items="eventItems"
                class="pa-6"
            >
            </hb-data-table>
        </hb-card>

        <hb-global-notification
            v-model="snackbar"
            :type="type"
            :list="type === 'error' && list ? errors.items : []"
            :description="description"
        >
        </hb-global-notification>

    </div>
</template>
<script type="text/babel">

    export default {
        name: "DesignSystemForms",
        data: function() {
            return {
                active: '',
                type: '',
                list: false,
                description: '',
                snackbar: false,
                textFieldFocused: false,
                textFieldFirstNameFocused: false,
                textFieldLastNameFocused: false,
                propHeaders: [
                    { text: 'Name', value: 'name', width: '140' },
                    { text: 'Type', value: 'type' },
                    { text: 'Default', value: 'default', width: '110' },
                    { text: 'Required', value: 'required', width: '110' },
                    { text: 'Description', value: 'description' },
                ],
                propItems: [
                    { name: 'label', type: 'string', default: 'undefined', required: 'yes', description: 'Sets the label text. This prop is required.' },
                    { name: 'required', type: 'boolean', default: 'false', required: 'no',  description: 'When changed to true, this adds an "*" to the end of the label title which signifies that the row input is required.' },
                    { name: 'tooltip-header', type: 'string', default: 'undefined', required: 'no',  description: 'Sets the label tooltip hover-over header text. You can also use the "v-slot:tooltipHeader" slot to set the tooltip header content (useful for when you need to set more than just a basic string, and need to set custom html.' },
                    { name: 'tooltip-body', type: 'string', default: 'undefined', required: 'no',  description: 'Sets the label tooltip hover-over content text.  You can also use the "v-slot:tooltipBody" slot to set the tooltip body content (useful for when you need to set more than just a basic string, and need to set custom html.' },
                    { name: 'auto-layout', type: 'boolean', default: 'true', required: 'no', description: 'When changed to false, this removes the default spacing between rows in the content area. Usually you want to leave this value as true. Cases where you might want to change this value to false would be when using condensed input boxes within a long sentence in order to decrease the spacing when the sentence breaks into multiple lines.' },
                    { name: 'full', type: 'boolean', default: 'false', required: 'no',  description: 'When changed to true, this changes the input max-width from the standard 370px to no limit. Use this for when you need the right side content to go full width. This prop affects the whole input container so if you have multiple rows and single line row as one of the rows, you need to manually add a "max-width: 370px" inline style or class to that single line input to adhere to design standards. Does the same function as the "multiple" prop.' },
                    { name: 'multiple', type: 'boolean', default: 'false', required: 'no',  description: 'When changed to true, this changes the input max-width from the standard 370px to no limit. Use this for when there are multiple inputs needed on one line. This prop affects the whole input container so if you have multiple rows and single line row as one of the rows, you need to manually add a "max-width: 370px" inline style or class to that single line input to adhere to design standards. Does the same function as the "full" prop.' },
                    { name: 'editable', type: 'boolean', default: 'false', required: 'no',  description: 'When changed to true, this sets the form row as an editable type form row. This enables the edit slot. Display slot is always enabled and available for use regardless of whether editable is set to true. If you want a form row that is both editable and read-only depending on certain actions or locations, simple use both display and edit slots for content and set the "editable" prop to true or false based upon your need. For example, if editable prop is set to false and the display slot is used, the display slot will not show any hover functionality and will not be clickable/editable. Once you set it to true, the display slot will now be interactable/editable.' },
                    { name: 'active', type: 'string', default: 'undefined', required: 'no',  description: 'When editable prop is set to true and when this prop is changed to match the text of a row label (case sensitive), that row will become the active editable row. All sibling form rows should be synced as detailed in the code examples so that only one form row is editable at a time and clicking on a different row will cancel and close the current one being edited. You can set this prop to an empty string to turn off all rows. After a row is successfully saved to the database, you should set this prop to "saved" or an empty string to revert the row to the regular display mode.' },
                    { name: 'empty', type: 'boolean', default: 'false', required: 'no',  description: 'When editable prop is set to true and when this prop is changed to true, this sets the "Click to Add" link in the display slot. This should be dynamically set to true when the input value is empty and false when it is not.' },
                    { name: 'small', type: 'boolean', default: 'false', required: 'no',  description: 'When changed to true, this changes the label width to 100px instead of the default 150px.' },
                    { name: 'medium', type: 'boolean', default: 'false', required: 'no',  description: 'When changed to true, this changes the label width to 125px instead of the default 150px.' },
                    { name: 'large', type: 'boolean', default: 'false', required: 'no',  description: 'When changed to true, this changes the label width to 175px instead of the default 150px.' },
                    { name: 'extra-large', type: 'boolean', default: 'false', required: 'no',  description: 'When changed to true, this changes the label width to 200px instead of the default 150px.' },
                    { name: 'description', type: 'string', default: 'undefined', required: 'no',  description: 'Sets the input description text with correct font size and color. This will be used sometimes, but the majority of inputs will not need this prop. If the input does need a description, please use this prop instead of manually coding the description text because we need to maintain uniform styling.' },
                    { name: 'start-open', type: 'boolean', default: 'false', required: 'no',  description: 'When changed to true and the "editable" prop is set to true, this changes the form field to display as open by default, useful for when you want fields open when a form is first filled out, but then revert to editable functionality after that. If so, you would have to make sure to add additional vue logic to set the "start-field" prop back to false after the first save of the entire form, or set it to false when the field already has data filled on component load.' },
                    { name: 'hide-actions', type: 'boolean', default: 'false', required: 'no',  description: 'When changed to true and the "editable" prop is set to true, this hides the "Save" and "Cancel" action buttons. Useful in conjunction with "start-open" prop when you want a big form to have all fields open initially with no inline action buttons. If so, you would have to make sure to add additional vue logic to set the "hide-actions" prop back to false after the first save of the entire form, or set it to false when the field already has data filled on component load.' },
                    { name: 'disable-save', type: 'boolean', default: 'false', required: 'no',  description: 'When changed to true and the "editable" prop is set to true, this disables the "Save" button.' },
                    { name: 'divider', type: 'boolean', default: 'true', required: 'no',  description: 'When changed to false, the bottom divider/border will be turned off.' },
                    { name: 'before-clickable', type: 'boolean', default: 'false', required: 'no',  description: 'When changed to true, clicking the "before" slot content will initiate the "editStart" event.' },
                    { name: 'description-clickable', type: 'boolean', default: 'true', required: 'no',  description: 'When changed to false, clicking the "description" prop text will NOT initiate the "editStart" event.' },
                    { name: 'after-clickable', type: 'boolean', default: 'false', required: 'no',  description: 'When changed to true, clicking the "after" slot content will initiate the "editStart" event.' },
                    { name: 'last', type: 'boolean', default: 'false', required: 'no',  description: 'When changed to true, this will increased the min-height of the form row to 168px and turn off the bottom divider for that row only, which is useful for the last entry in a modal with a form which should usually be an elongated height for visual niceness. Also since this removes the bottom divider, this prevents the double divider effect when an HbBottomActionBar is directly below.' },
                ],
                slotHeaders: [
                    { text: 'Name', value: 'name' },
                    { text: 'Description', value: 'description' },
                ],
                slotItems: [
                    { name: 'edit', description: 'When "editable" prop is set to true, this slot is where you place the code for when the input is in edit mode.' },
                    { name: 'display', description: 'This slot is where you place the code for when the editable input is in display mode.' },
                    { name: 'tooltipHeader', description: 'This slot is where you can set the tooltip header content.' },
                    { name: 'tooltipBody', description: 'This slot is where you can set the tooltip body content.' },
                    { name: 'before', description: 'This slot is where you can set content before the "default" "edit" and "display" slots. Good for when you need a constant element to show at all times. This element does not initiate the "editStart" event. This is to allow this element to have separate/manual click events if needed.' },
                    { name: 'after', description: 'This slot is where you can set content after the "default" "edit" and "display" slots. Good for when you need a constant element to show at all times. This element does not initiate the "editStart" event. This is to allow this element to have separate/manual click events if needed.' },
                ],
                eventHeaders: [
                    { text: 'Name', value: 'name' },
                    { text: 'Description', value: 'description' },
                ],
                eventItems: [
                    { name: '@cancel', description: 'Event for when cancel button is clicked on editable form row inputs.' },
                    { name: '@save', description: 'Event for when save button is clicked on editable form row inputs.' },
                    { name: '@editStart', description: 'Event for when an editable form row is clicked. The label text is passed along with this emit.' },
                ],
                textField: '',
                textFieldType: 'Contact',
                textFieldMethod: 'Phone Call',
                textFieldReason: '',
                textFieldFirstName: 'Albus',
                textFieldLastName: 'Dumbledore',
                selectField: 'Office',
                autocompleteField: '',
                textareaField: 'HbForm must use "full" prop when using HbTextarea - textareas must be full width instead of the normal 370px width for inputs.',
                fileInputField: [],
                textFieldEmpty: '',
                selectFieldEmpty: '',
                autocompleteFieldEmpty: '',
                textareaFieldEmpty: '',
                slotExample: 'v-slot:edit',
                textFieldEditable: '',
                textFieldEditableInput: '',
                textFieldEditablePrefilled: 'When Input Content Is Already Filled on Page Load',
                textFieldEditablePrefilledInput: 'When Input Content Is Already Filled on Page Load',
                toggleOpen: false,
                combobox: ['Harry','Ron'],
                items: ['Harry','Ron','Albus','Severus','Voldemort'],
            };
        },
        methods: {
            handleEmittedCancel(label){
                switch(label){
                    case 'Empty':
                        this.textFieldEditableInput = this.textFieldEditable;
                        break;
                    case 'Already Filled':
                        this.textFieldEditablePrefilledInput = this.textFieldEditablePrefilled;
                        break;
                }
                this.list = false;
                this.description = 'The editing of the form row: "' + label +'" was canceled. This "@cancel" action is emitted from it\'s base level custom component. This message is not intended for a normal user to see. It is just here to notify developers of the emitted event.'
                this.type = 'error';
                this.snackbar = true;
            },
            handleEmittedSave(label){
                let param = '';
                switch(label){
                    case 'Empty':
                        param = 'name';
                        break;
                    case 'Already Filled':
                        param = 'address';
                        break;
                }
                this.$validator.validate(param).then(valid => {
                    if (!valid) {
                        this.list = true;
                        this.description = "There are errors in your form, correct them before continuing."
                        this.type = 'error';
                        this.snackbar = true;
                        return;
                    } else {
                        switch(label){
                            case 'Empty':
                                this.textFieldEditable = this.textFieldEditableInput;
                                break;
                            case 'Already Filled':
                                this.textFieldEditablePrefilled = this.textFieldEditablePrefilledInput;
                            break;
                        }
                        this.active = '';
                        this.description = 'The saving of form row: "' + label + '" was successful. This "@save" action is emitted from it\'s base level custom component. This message is not intended for a normal user to see. It is just here to notify developers of the emitted event.'
                        this.type = 'success';
                        this.snackbar = true;
                    }
                });
            },
            validateOpenForm(){
                this.$validator.validateAll('default').then(valid => {
                    if (!valid) {
                        this.list = true;
                        this.description = "There are errors in your form, correct them before continuing."
                        this.type = 'error';
                        this.snackbar = true;
                    } else {
                        this.description = "All fields passed validation. This message is not intended for a normal user to see. It is just here to notify developers that the validation was successful."
                        this.type = 'success';
                        this.snackbar = true;
                    }
                });
            },
            editHasStarted(label){
                this.description = 'The editing of form row "' + label + '" has started.  This "@editStart" action is emitted from it\'s base level custom component.'
                this.type = 'success';
                this.snackbar = true;
            }
        }
    }
</script>

<style scoped>

</style>
